<template>
  <div class="aali_tm_section" id="service">
    <div class="aali_tm_service">
      <div class="container">
        <div class="service_list">
          <ul>
            <li class="simple wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner">
                <div class="aali_tm_main_title" data-text-align="left" data-color="dark">
                  <span></span>
                  <h3>Pilares del Pensamiento</h3>
                  <!-- <p>We offer high quality products for competitive prices. Our main goal is customer satisfaction, which we obtain through market orientation of ongoing service and support.</p> -->
                </div>
              </div>
            </li>

            <li class="wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.2s">
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="back" src="img/svg/think.png" alt="" />
                </span>

                <div class="title">
                  <h3>Sin soluciones obvias, trabajando inteligentemente</h3>
                </div>
                <div class="text">
                  <p></p>
                </div>
              </div>
            </li>
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="back" src="img/svg/key.png" alt="" />
                </span>
                <div class="title">
                  <h3>Implacablemente orientados por resultados, siempre buscando la mejor solución</h3>
                </div>
                <div class="text">
                  <p></p>
                </div>
              </div>
            </li>
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="back" src="img/svg/rocket.png" alt="" />
                </span>
                <div class="title">
                  <h3>Creamos y entregamos valor más allá del esperado, generando beneficios a clientes y su entorno</h3>
                </div>
                <div class="text">
                  <p></p>
                </div>
              </div>
            </li>
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="back" src="img/svg/build.png" alt="" />
                </span>
                <div class="title">
                  <h3>Exploramos nuevas fronteras, identificando las tendencias y tecnologías que tendrán el mayor impacto</h3>
                </div>
                <div class="text">
                  <p></p>
                </div>
              </div>
            </li>
            <li class="wow fadeInLeft" data-wow-duration="1s">
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="back" src="img/svg/vision.png" alt="" />
                </span>
                <div class="title">
                  <h3>Nos caracterizamos por una visión transversal del mercado, entendiendo que la disrupción proviene, generalmente, de fuera de una industria</h3>
                </div>
                <div class="text">
                  <p></p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <span class="square_left moving_effect" data-direction="y" data-reverse="yes"></span>
      <span class="square_right moving_effect" data-direction="y" data-reverse="yes"></span>
    </div>
  </div>
</template>

<script>
import { msg } from './svgImage'
export default {
  name: 'Service',
  data() {
    return {
      msg,
      active: 0
    }
  },
  props: {
    dark: Boolean
  },
  mounted() {
    let VanillaTilt = require('vanilla-tilt')
    VanillaTilt.init(document.querySelectorAll('.tilt-effect'), {
      maxTilt: 6,
      easing: 'cubic-bezier(.03,.98,.52,.99)',
      speed: 500,
      transition: true
    })
  },
  methods: {
    close() {
      this.active = 0
    }
  }
}
</script>
