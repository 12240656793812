<template>
  <div class="aali_tm_section">
    <div class="">
      <div class="about_inner">
        <div class="content" style="margin: 100px">
          <div class="title wow fadeInUp" data-wow-duration="1s">
            <h1>Aviso de privacidad</h1>
          </div>
          <div class="text wow fadeInUp" style="margin-top: 20px" data-wow-duration="1s" data-wow-delay="0.2s">
            <div class="WordSection1">
              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Política de Privacidad<o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >Enphoque, Competitividad y Estrategia, S.A. de C.V. (ENPHOQUE) es responsable de los consultores que laboran aquí y en general del personal administrativo que se encuentra dentro de
                  las oficinas de ENPHOQUE.
                </span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >La protección de sus datos personales es muy importante para ENPHOQUE, razón por la cual, este AVISO DE PRIVACIDAD, ha sido elaborado para dar cumplimiento a la LEY FEDERAL DE
                  PROTECCIÓN DE DATOS PERSONALES EN POSESION DE LOS PARTICULARES (la “Ley”) y su Reglamento y tiene como finalidad informarle el tipo de datos personales que recabamos de usted, la
                  manera en que los usamos, manejamos, aprovechamos y con quien los compartimos.
                </span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >ENPHOQUE es responsable del uso y protección de sus datos personales y sensibles los cuales serán tratados en base a los principios de licitud, consentimiento, información, calidad,
                  lealtad, responsabilidad, proporcionalidad y finalidad, en términos de la Ley.
                </span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><o:p>&nbsp;</o:p></span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Información Personal y Sensible <o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX">Como cliente de alguno de nuestros servicios le podemos solicitar información personal y sensible entre otra y según el caso, relativa a:</span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >Datos de identificación, tales como nombre completo, edad, domicilio, ocupación, Registro Federal de Contribuyente (RFC), correo electrónico, números telefónicos, así como datos
                  patrimoniales tales como información general de su cuenta bancaria.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><o:p>&nbsp;</o:p></span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Utilización de la Información Personal y Sensible <o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >ENPHOQUE recaba información personal y sensible, a través de medios electrónicos, físicos o vía telefónica, de manera escrita y/o verbal para el cumplimiento de las siguientes
                  finalidades:</span
                >
              </p>

              <p class="MsoListParagraphCxSpFirst" style="text-align: justify; text-indent: -18pt; mso-list: l0 level1 lfo1">
                <span lang="ES-MX" style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol"
                  ><span style="mso-list: Ignore">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span
                ><span lang="ES-MX">Brindar información acerca de nuestros servicios.</span>
              </p>

              <p class="MsoListParagraphCxSpMiddle" style="text-align: justify; text-indent: -18pt; mso-list: l0 level1 lfo1">
                <span lang="ES-MX" style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol"
                  ><span style="mso-list: Ignore">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span
                ><span lang="ES-MX">Creación de expedientes y actualización.</span>
              </p>

              <p class="MsoListParagraphCxSpMiddle" style="text-align: justify; text-indent: -18pt; mso-list: l0 level1 lfo1">
                <span lang="ES-MX" style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol"
                  ><span style="mso-list: Ignore">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span
                ><span lang="ES-MX">Emitir facturas y recibos de pago.</span>
              </p>

              <p class="MsoListParagraphCxSpMiddle" style="text-align: justify; text-indent: -18pt; mso-list: l0 level1 lfo1">
                <span lang="ES-MX" style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol"
                  ><span style="mso-list: Ignore">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span
                ><span lang="ES-MX">Evaluar la calidad del servicio prestado.</span>
              </p>

              <p class="MsoListParagraphCxSpLast" style="text-align: justify; text-indent: -18pt; mso-list: l0 level1 lfo1">
                <span lang="ES-MX" style="font-family: Symbol; mso-fareast-font-family: Symbol; mso-bidi-font-family: Symbol"
                  ><span style="mso-list: Ignore">·<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span
                ><span lang="ES-MX">El envío de correspondencia, dentro del desarrollo de actividades publicitarias y promocionales de los servicios de ENPHOQUE.</span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Información Personal y Sensible para Fines de Investigación Empresarial y Fines Estadísticos <o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >ENPHOQUE recaba y utiliza información personal y sensible para efectos de realizar investigaciones de negocios, para elaborar estadísticas y/o evaluar el impacto de nuestros
                  programas en la supervivencia de su negocio o en la vida de emprendedores, en este caso, en base a la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESION DE LOS PARTICULARES,
                  se llevará a cabo el procedimiento de disociación de la información, lo cual significa que los datos personales y/o sensibles no pueden asociarse al titular ni permitir, por su
                  estructura, contenido o grado de desagregación, la identificación del mismo.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><o:p>&nbsp;</o:p></span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Limitación en la Transferencia de Información Personal y Sensible<o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >su información podrá ser compartida única y exclusivamente con los capacitadores de ENPHOQUE, así como con empresas como Facebook, cuando sea necesario para la presentación o
                  consecución de los servicios que solicita, como parte del seguimiento que se hacen a los talleres que recibe, o como parte de las estadísticas de los cursos realizados, así como para
                  el debido cumplimiento de las obligaciones contraídas, así como también podrán enviarse a Autoridades y/o Dependencias Gubernamentales cuando sean solicitados a este negocio de forma
                  legal. Si no manifiestas su oposición para que tus datos personales sean transferidos se entenderá que has otorgado su consentimiento para ello en el entendido de que en cualquier
                  momento podrá manifestar su oposición.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><o:p>&nbsp;</o:p></span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Limitación de Uso o Divulgación de Información Personal y Sensible<o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify"><span lang="ES-MX">Puede limitar el uso y divulgación de su información personal a través de los siguientes medios:</span></p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX">Por correo electrónico a la siguiente dirección: <a href="mailto:socialmediaenphoque@gmail.com">socialmediaenphoque@gmail.com</a> </span>
              </p>

              <p class="MsoNormal" style="text-align: justify"><span lang="ES-MX">Comunicarte al número telefónico: 55 47673345</span></p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX">Encargado del tratamiento de datos personales y sensibles de ENPHOQUE: Nelly Beatriz Meza Rodríguez. Director.</span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><o:p>&nbsp;</o:p></span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Derechos<span style="mso-spacerun: yes">  </span>ARCO<o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify"><span lang="ES-MX">(Acceso, rectificación, cancelación u oposición)</span></p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >El ejercicio de sus derechos de acceso, rectificación, cancelación y oposición o la revocación, total o parcial, del consentimiento, podrá efectuarlo presentando su solicitud por
                  escrito en nuestro domicilio dirigido al Responsable del tratamiento de datos personales y sensibles ENPHOQUE, o bien, vía correo electrónico a
                  <a href="mailto:socialmediaenphoque@gmail.com">socialmediaenphoque@gmail.com</a> o llamando al número telefónico 55 47673345, en el entendido de que una vez plasmados en un
                  instrumento legal o en cualquier otro documento físico, electrónico en cualquier otro medio magnético o de cualquier tecnología, no podrá usted ejercer sobre ellos ninguno de los
                  referidos derechos, pudiendo hacerlo solamente respecto de los que se conservan en la base de datos de ENPHOQUE. En caso de solicitar la rectificación de sus datos personales, deberá
                  indicar las modificaciones a realizarse y aportar la documentación oficial necesaria que sustente su petición.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >ENPHOQUE responderá a la dirección de correo electrónico, que el titular de los Datos Personales designe en su solicitud, pudiendo indicar en la misma un medio distinto para recibir
                  dicha respuesta. ENPHOQUE responderá en un plazo máximo de 20 (veinte) días calendario o el máximo permitido por la ley. La respuesta de ENPHOQUE indicará si la solicitud de acceso,
                  rectificación, cancelación u oposición es procedente y, en su caso, se hará efectiva la determinación dentro de los 15 (quince) días calendario siguientes a la fecha en que comunique
                  la respuesta o el máximo permitido por la ley. Los plazos podrán ser ampliados en los términos que señale la Ley aplicable.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >La temporalidad del manejo de tus datos personales será por un plazo de tiempo en que subsista la asesoría o consultoría que se te proporcione en ENPHOQUE, más un plazo de 5 (cinco)
                  años después de la última cita o atención que haya tenido por parte de ENPHOQUE, mismo que<span style="mso-spacerun: yes">  </span>podrá ampliarse cuando<span
                    style="mso-spacerun: yes"
                    >  </span
                  >así lo requiera la Legislación Mexicana aplicable, pudiendo oponerte al manejo de los mismos en cualquier momento que lo consideres oportuno según el mecanismo señalado en el
                  presente apartado, o en su caso, en el momento en que dichos datos personales hayan dejado de ser necesarios para el cumplimiento de las finalidades previstas en el presente Aviso de
                  Privacidad y las disposiciones legales aplicables, motivo por el cual los datos personales serán cancelados, dando así cumplimiento a lo dispuesto por el artículo 11 de la Ley.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><o:p>&nbsp;</o:p></span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Uso de Tecnologías de Rastreo en Nuestro Sitio de Internet<o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><a href="https://www.enphoque.com/">https://www.enphoque.com/</a> </span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >Al ingresar a la página web de ENPHOQUE, el servidor de ENPHOQUE guarda la siguiente información: el dominio o dirección IP de la computadora que solicita el acceso, la fecha de
                  acceso, el código de respuesta HTTP, la página web de la cual ingresa y el número de bytes transferidos a través de la conexión, tiempo de navegación en nuestra página de Internet,
                  secciones consultadas, y páginas de Internet accedidas previo a la nuestra. La información enunciada se obtiene con fines de seguridad en el sistema, así como para monitorear su
                  comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página de Internet, así como ofrecerle nuevos servicios basados en
                  sus preferencias. Los datos personales que tú nos entregues a través del formulario de contacto serán parte de nuestra base de datos para ser usados con la siguiente finalidad:</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >El envío de actualizaciones de los servicios por medios tradicionales y electrónicos, de información técnica, operativa y comercial acerca de productos y servicios de ENPHOQUE,
                  actualmente y en el futuro.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >Desarrollar estudios internos sobre sus intereses y comportamiento, con el objetivo de comprender mejor sus necesidades e intereses y darle un mejor servicio o proveerles
                  información relacionada.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX">El envío de correspondencia, correos electrónicos o contacto telefónico, dentro del desarrollo de actividades publicitarias, promocionales.</span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >Nuestra página de internet <a href="https://www.enphoque.com/">https://www.enphoque.com/</a> , puede contener links o enlaces a páginas de internet externas que no corresponden a
                  ENPHOQUE y, por tanto, no guardan relación alguna con nosotros. Te recomendamos revisar y leer las políticas de privacidad, así como los términos de uso de dichos sitios web externos
                  antes de hacer uso de estos.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><o:p>&nbsp;</o:p></span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><o:p>&nbsp;</o:p></span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"><o:p>&nbsp;</o:p></span>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Modificaciones al Aviso de Privacidad<o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones y cuando esto suceda, modificaremos la fecha de la última actualización. Con la finalidad de
                  que esté informado al respecto le invitamos a consultar el presente Aviso de Privacidad en el área de Recepción de ENPHOQUE.</span
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX"><o:p>&nbsp;</o:p></span></b
                >
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <b
                  ><span lang="ES-MX">Aceptación del Aviso de privacidad<o:p></o:p></span
                ></b>
              </p>

              <p class="MsoNormal" style="text-align: justify">
                <span lang="ES-MX"
                  >Si tienes alguna duda sobre el presente aviso de privacidad o no se encuentra de acuerdo con sus términos, deberás manifestar tu oposición mediante el envío de un correo electrónico
                  a la dirección <a href="mailto:socialmediaenphoque@gmail.com">socialmediaenphoque@gmail.com</a>, de lo contrario, se entenderá que has otorgado tu completo consentimiento.</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from './svgImage'
export default {
  name: 'About',
  data() {
    return {
      download: `Download CV ${download}`
    }
  }
}
</script>
