import Vue from "vue";
import VueRouter from "vue-router";
import PrivacyPolicy from "./views/PrivacyPolicy.vue";
import Index from "./views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/privacy-policy",
    name: "Terminos y condiciones",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
