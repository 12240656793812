<template>
  <div class="aali_tm_section" id="contact">
    <div class="aali_tm_contact">
      <div class="container">
        <div class="aali_tm_main_title" data-text-align="center" data-color="dark">
          <!-- <span>Ponerse en contacto</span> -->
          <h3>Contáctanos</h3>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <div class="text">
              <p>De Lunes a Viernes de 9:00 am a 18:00 pm Ciudad de México</p>
            </div>
            <ul>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    `<span>Teléfono</span>
                  <a href=https://wa.me/525547673345><h3>+52 1 55 4767 3345</h3></a>` + call
                  "
                ></div>
              </li>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    exp +
                    `<span>Direcciones</span>
                  <h3>
                    <a class='line_anim' target='new' href='https://maps.app.goo.gl/KjqZzFC56J5S2aEw8'
                      >Av. Paseo de la Reforma 333-Oficina 4, Cuauhtémoc, 06500 Ciudad de México, CDMX</a
                    >
                  </h3><br/>
                  <h3>
                    <a class='line_anim' target='new' href='https://maps.app.goo.gl/cP2tqqKhAn2k91K79'
                      >Descartes 60, Anzures, Miguel Hidalgo, 11590 Ciudad de México, CDMX</a
                    >
                  </h3>`
                  "
                ></div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
              <form class="contact_form" id="contact_form" @submit.prevent="handleSubmit">
                <div
                  class="returnmessage"
                  data-success="Tu mensaje fue recibido, Pronto te contantaremos."
                  :style="{
                    display: msgSent ? 'block' : 'none'
                  }"
                >
                  <span class="contact_success"> Tu mensaje fue recibido, Pronto te contantaremos. </span>
                </div>
                <div class="empty_notice" :style="{ display: error ? 'block' : 'none' }">
                  <span>Agregar los campos obligatorios</span>
                </div>
                <div class="input_list">
                  <ul>
                    <li>
                      <input id="name" type="text" v-model="fromData.name" placeholder="Tu Nombre" />
                    </li>
                    <li>
                      <input id="email" type="text" v-model="fromData.email" placeholder="Tu Email" />
                    </li>
                    <li>
                      <input id="phone" type="number" placeholder="Tu número" name="phn" v-model="fromData.phone" />
                    </li>
                  </ul>
                </div>
                <div class="message_area">
                  <textarea id="message" placeholder="Comentarios" v-model="fromData.msg"></textarea>
                </div>
                <div class="aali_tm_button">
                  <a id="send_message" href="#" @click.prevent="handleSubmit"><span v-html="'Enviar Contacto' + msgSent"> </span></a>
                </div>

                <!-- If you want change mail address to yours, just open "modal" folder >> contact.php and go to line 4 and change detail to yours.  -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <span class="square moving_effect" data-direction="y" data-reverse="yes"></span>
    </div>
  </div>
</template>

<script>
import { call, exp, msgSent } from './svgImage'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios'
import Vue from 'vue'

export default {
  name: 'Contact',
  data() {
    return {
      msgSent: false,
      call,
      exp,
      fromData: {
        name: '',
        email: '',
        phone: '',
        msg: ''
      },
      error: false,
      success: false
    }
  },
  methods: {
    async handleSubmit() {
      this.success = true
      this.error = false
      this.msgSent = false
      Vue.use(VueReCaptcha, { siteKey: '6LctrxwgAAAAALJ8QesFffnyURTCxnmgkxiwJVF2' })
      // Execute reCAPTCHA with action "login".
      const { name, email, phone, msg } = this.fromData
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('contact')
      if (name && email && phone && msg) {
        const params = {
          name,
          msg,
          email,
          phone,
          msg,
          token
        }
        await axios
          .post('https://us-central1-enphoque-5024c.cloudfunctions.net/sendContact', params)
          .then((response) => {
            this.info = response
            this.success = false
            this.msgSent = true
            this.error = false
            this.fromData = {
              name: '',
              email: '',
              phone: '',
              msg: ''
            }
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        this.error = true
      }
    }
  }
}
</script>


<style>
.aali_tm_contact .left ul li h3 {
  font-weight: 300 !important;
  color: gray !important;
}
.aali_tm_contact .left ul li a {
  color: gray !important;
}
</style>