<template>
  <div class="aali_tm_header">
    <div class="container">
      <div class="inner">
        <div class="logo">
          <a class="light" href="#"><img src="img/logo/logo.png" alt="" /></a>
          <a class="dark" href="#"><img :src="dark ? 'img/logo/logo.png' : 'img/logo/dark.png'" alt="" /></a>
        </div>
        <div class="menu">
          <ul class="anchor_nav">
            <li class="current"><a href="#home">Inicio</a></li>
            <li><a href="#about">Nosotros</a></li>
            <li><a href="#portfolio">Apoyamos en</a></li>
            <li><a href="#service">Negocio</a></li>
            <!-- <li><a href="#testimonial">Testimonial</a></li>
            <li><a href="#blog">Blog</a></li> -->
            <li><a href="#contact">Contacto</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { scroll_, stickyNav } from '../../utils/utils'

export default {
  name: 'Header',
  props: {
    dark: Boolean
  },
  mounted() {
    window.addEventListener('scroll', scroll_)
    window.addEventListener('scroll', stickyNav)
  }
}
</script>
<style scoped>
li.current a:hover {
  color: #d6b34f !important;
}
</style>
