<template>
  <div class="aali_tm_section">
    <div class="aali_tm_skills">
      <div class="container">
        <div class="skills_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <div class="aali_tm_main_title" data-text-align="left" data-color="light">
              <span>Buscamos</span>
              <h3>
                Dinamizar
                <br />
                los territorios
              </h3>
              <p>
                Mediante el diseño e implementación de proyectos innovadores y competitivos que a su vez promuevan el crecimiento y desarrollo de las economías de forma sostenible aprovechando sus
                capacidades instaladas, apostando en su potencial y migrando hacia actividades de mayor valor agregado.
              </p>
            </div>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="aali_tm_main_title" data-color="light">
              <span>Principales Sectores de Incursión</span>
              <p>
               <ul>
                <li>Industria 4.0</li>
                <li>Emprendimiento</li>
                <li>Smart Cities</li>
                <li>Educación</li>
                <li>Desarrollo de Cadenas de Valor</li>
                <li>Automotriz</li>
                <li>Agricultura</li>
                <li>Desarrollo de Clústeres</li>
               </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
      <span class="border moving_effect" data-direction="x" data-reverse="yes"></span>
    </div>
  </div>
</template>

<script>
import { activeSkillProgress } from '../utils/utils'

export default {
  name: 'Skills',
  mounted() {
    window.addEventListener('scroll', activeSkillProgress)
  }
}
</script>
