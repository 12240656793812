 <template>
  <div class="socials">
    <ul>
      <li>
        <a target="new" href="https://www.linkedin.com/company/enphoque/">
          <img :src="'data:image/svg+xml;charset=utf-8,' + linkedin" />
        </a>
      </li>
      <li>
        <a target="new" href="https://www.facebook.com/EnphoqueMX">
          <img :src="'data:image/svg+xml;charset=utf-8,' + fb" />
        </a>
      </li>
      <!-- <li>
        <a target="new" href="#">
          <img :src="'data:image/svg+xml;charset=utf-8,' + insta" />
        </a>
      </li> -->
    </ul>
  </div>
</template>
<script>
import { fb, insta, msg, twitter, linkedin } from './svgImage'

export default {
  name: 'socialMedia',
  data() {
    return {
      msg,
      fb,
      insta,
      twitter,
      linkedin
    }
  }
}
</script>
<style scoped>
li a img {
  width: 20px;
  color: white;
  filter: invert(1);
}
</style>
