<template>
  <div class="aali_tm_all_wrap" data-magic-cursor="show">
    <div class="logo">
      <a class="dark" href="#"><img :src="'img/logo/dark.png'" alt="" /></a>
    </div>
    <PrivacyPolicy />
  </div>
</template>

<script>
import PrivacyPolicy from '../components/PrivacyPolicy.vue'

export default {
  name: 'Index',
  components: {
    PrivacyPolicy
  }
}
</script>
