<template>
  <div class="aali_tm_section" id="about">
    <div class="aali_tm_about">
      <div class="about_inner">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/45-49.jpg" alt="" />
            <div class="main" data-img-url="img/about/1.jpg"></div>
            <span class="square moving_effect" data-direction="y" data-reverse="yes"></span>
            <span class="border"><span class="in moving_effect" data-direction="x" data-reverse="yes"></span></span>
          </div>
        </div>
        <div class="center">
          <div class="title wow fadeInUp" data-wow-duration="1s">
            <span class="small">Nosotros</span>
            <h3>Somos <span>Enphoque</span></h3>
            <h3>Competitividad, <span>Innovación y Estrategia</span></h3>
          </div>
          <div class="text wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
            <p>
              Nos enfocamos en el desarrollo de estrategias de alto impacto, innovación y nuevos modelos de negocio. Apoyamos a empresas, gobiernos e instituciones de soporte a navegar en un escenario
              global cada vez más complejo, incierto y volátil.
            </p>
            <p>
              Nos motiva ir más allá de lo evidente. Buscamos anticiparnos, co-crear y poner en la mesa propuestas novedosas para resolver los problemas más apremiantes de nuestros clientes e
              identificar oportunidades audaces que les permitan mantenerse un paso adelante.
            </p>
          </div>
          <!-- <div class="aali_tm_button wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.2s">
            <a href="resources/cv.pdf" download> <span v-html="download"> </span></a>
          </div> -->
        </div>
        <div class="right">
          <div class="image">
            <img src="img/thumbs/41-61.jpg" alt="" />
            <div class="main" data-img-url="img/about/2.jpg"></div>
            <div class="square moving_effect" data-direction="x" data-reverse="no"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { download } from './svgImage'
export default {
  name: 'About',
  data() {
    return {
      download: `Download CV ${download}`
    }
  }
}
</script>

<style scoped>
.aali_tm_about .center h3{
  font-size: 40px;
}
</style>